import React, { useState } from "react";

import FreeTextSearch from "../FreeTextSearch/FreeTextSearch";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.scss";
import Button from "../Button/Button";
import Logo from "../Logo/Logo";

const header = (props) => {
  const { onSearch } = props;
  return (
    <div className={classes.HeaderContainer}>
      <header className={classes.Header}>
        <Logo />
        <FreeTextSearch onSearch={onSearch} />
        <div className={classes.Header__logout}>
          {props.isAuthenticated && (
            <Button>
              <NavLink activeClassName="navbar__link--active" to="/logout">
                Log out
              </NavLink>
            </Button>
          )}
        </div>
      </header>
    </div>
  );
};

export default header;
