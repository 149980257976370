import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.scss";

const navigationItem = (props) => (
  <li className={classes.NavigationItem}>
    <NavLink className={classes.NavigationItem__Link} activeClassName={classes.active} to={props.link}>
      {props.children}
    </NavLink>
  </li>
);

export default navigationItem;
