import React from "react";
import classes from "./ValidationSummary.module.scss";
import { toSentaceCase } from "../../shared/utility";

const ValidationSummary = ({ invalidInputs }) => {
  return (
    invalidInputs && (
      <ul className={classes.ValidationSummary}>
        {invalidInputs.map(
          (invalidInput) =>
            !invalidInput.config.valid &&
            invalidInput.config.validation && (
              <li
                key={invalidInput.id}
                className={classes.ValidationSummary__invalidInput}
              >
                Please enter a valid {toSentaceCase(invalidInput.id)}
              </li>
            )
        )}
      </ul>
    )
  );
};

export default ValidationSummary;
