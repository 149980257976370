import React, { useState } from "react";
import { connect } from "react-redux";

import { checkValidity, isFormValid } from "../../../shared/utility";
import Container from "../../../hoc/Layout/Container/Container";
import FormControlsCreator from "../../../components/FormControlsCreator/FormControlsCreator";
import Button from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spinner";
import classes from "./ForgottenEmail.module.scss";
import Error from "../../../components/Error/Error";
import useResetProfileError from "../../../hooks/useResetProfileError";
import * as actions from "../../../store/actions/index";

const ForgottenEmail = (props) => {
  const { loading, error, onSendForgottenPasswordEmail } = props;

  const controlsInitialState = {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Mail Address",
        autoComplete: "email",
      },
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      inputChangedHandler: "inputChangedHandler",
    },
  };

  const [controls, setControls] = useState(controlsInitialState);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [showMessasge, setShowMessage] = useState(false);

  useResetProfileError();

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          controls[controlName].validation
        ),
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isFormValid(controls)) {
      onSendForgottenPasswordEmail(controls.email.value)
        .then(() => setShowMessage(true))
        .catch((error) => {
          setShowMessage(false);
        });
    } else {
      setHasValidationErrors(true);
    }
  };

  let form = (
    <FormControlsCreator
      showValidationSummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
      }}
    />
  );

  if (loading) {
    form = <Spinner />;
  }

  let errorMessage = null;

  if (error) {
    errorMessage = <Error errors={[error]} />;
  }

  return (
    <Container>
      {/* <div className={classes.ForgottenEmail}> */}
      <h1>Forgotten password</h1>

      {errorMessage}
      <form onSubmit={submitHandler}>
        {showMessasge && (
          <p>Password reset email sent, please check your inbox!</p>
        )}
        {form}
        <Button classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}>
          -- SUBMIT --
        </Button>
      </form>
      {/* </div> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendForgottenPasswordEmail: (email) => {
      return dispatch(actions.sendForgottenPasswordEmail(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenEmail);
