import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import axios from "axios";

import rootReducer from "./store/reducers/rootReducer";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// const logger = (store) => {
//   return (next) => {
//     return (action) => {
//       console.log('[Middleware] Dispatching', action);
//       const result = next(action);
//       console.log('[Middleware] next state', store.getState());
//       return result;
//     }
//   }
// }

//Base config for axios

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

//To be used when we use middleware

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//     : null;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./store/reducers/rootReducer", () => {
      store.replaceReducer(rootReducer);
    });
  }
}

const render = (Component) => {
  return ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
// If you want your app to work offline and load faster, you can change - -
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
