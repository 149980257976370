import * as actionTypes from "./actionsTypes";
import axios from "axios";

export const fetchProfileStart = () => {
  return {
    type: actionTypes.FETCH_PROFILE_START,
  };
};

export const fetchProfileSuccess = (profile) => {
  return {
    type: actionTypes.FETCH_PROFILE_SUCCESS,
    profile: profile,
  };
};

export const fetchProfileFail = (error) => {
  return {
    type: actionTypes.FETCH_PROFILE_FAIL,
    error: error,
  };
};

export const fetchProfile = () => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `${token}` };

  const userId = localStorage.getItem("userId");

  return (dispatch) => {
    dispatch(fetchProfileStart());

    return axios
      .get("/api/user/")
      .then((response) => {
        return dispatch(fetchProfileSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(fetchProfileFail(err.response.message));
      });
  };
};

export const updateProfileStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_START,
  };
};

export const updateProfile = (profile, userId) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    dispatch(updateProfileStart());

    let form_data = new FormData();

    for (const field in profile) {
      if (field === "profilePhoto") {
        for (const [key, value] of Object.entries(profile[field].content)) {
          console.log(`${key}: ${value}`);
          form_data.append(`profilePhoto${key}`, value, value.name);
        }
      } else {
        form_data.append(field, profile[field]);
      }
    }

    return axios
      .put("/api/profile/" + userId, form_data)
      .then((response) => {
        return dispatch(updateProfileSuccess({ completedOnBoarding: "true" }));
      })
      .catch((err) => {
        dispatch(updateProfileFail(err.response.data.error));
        return Promise.reject(err.response.data.error);
        // throw new Error(err.response.data.error);
      });
  };
};

export const updateProfileSuccess = (profile) => {
  return {
    profile,
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
  };
};

export const updateProfileFail = (error) => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAIL,
    error: error,
  };
};
