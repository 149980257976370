import * as actionTypes from "./actionsTypes";
import axios from "axios";

export const loadGarageStart = () => {
  return {
    type: actionTypes.LOAD_GARAGE_START,
  };
};

export const loadGarage = (userId) => {
  return (dispatch) => {
    dispatch(loadGarageStart());

    return axios
      .get(`/api/user/${userId}/garage/listings`)
      .then((response) => {
        return dispatch(loadGarageSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(loadGarageFail(err.message));
      });
  };
};

export const loadGaragePublic = (nickname) => {
  return (dispatch) => {
    dispatch(loadGarageStart());

    return axios
      .get(`/api/listings/${nickname}`)
      .then((response) => {
        return dispatch(loadGarageSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(loadGarageFail(err.response.data));
      });
  };
};

export const loadGarageSuccess = (vehicles) => {
  return {
    type: actionTypes.LOAD_GARAGE_SUCCESS,
    vehicles: vehicles,
  };
};

export const loadGarageFail = (error) => {
  return {
    type: actionTypes.LOAD_GARAGE_FAIL,
    error: error,
  };
};
