import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../store/actions/index";

const useResetProfileError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.resetProfileError());
    };
  }, [dispatch]);
};

export default useResetProfileError;
