import React from "react";
import classes from "./Menu.module.scss";
import NavigationItems from "../NavigationItems/NavigationItems.js";

const Menu = (props) => {
  return (
    <nav className={classes.Menu}>
      <NavigationItems></NavigationItems>
    </nav>
  );
};

export default Menu;
