import React, { Fragment } from "react";
import Input from "../Input/Input";
import ValidationSummary from "../ValidationSummary/ValidationSummary";

const FormControlsCreator = (props) => {
  const { controls, eventHandlers, disabled, showValidationSummary } = {
    ...props,
  };

  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key],
    });
  }

  const sortedArray = formElementsArray.sort(
    (a, b) => a.config.orderIndex - b.config.orderIndex
  );

  let formControls = sortedArray.map((formElement) => {
    const handler = eventHandlers[formElement.config.inputChangedHandler];

    return (
      <Input
        key={formElement.id}
        name={formElement.id}
        label={formElement.config.label}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched || showValidationSummary}
        disabled={formElement.config.disabled || disabled}
        changed={(event) => handler(event, formElement.id)}
      />
    );
  });

  return (
    <Fragment>
      {formControls}
      {showValidationSummary && (
        <ValidationSummary invalidInputs={sortedArray} />
      )}
    </Fragment>
  );
};

export default FormControlsCreator;
