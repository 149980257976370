import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = {
    errorMessage: "",
  };
  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }
  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }
  // A fake logging service 😬
  logErrorToServices = console.log;
  render() {
    if (this.state.errorMessage) {
      return <p>KAMRAN SAYSSSS {this.state.errorMessage}</p>;
    }
    return this.props.children;
  }
}
