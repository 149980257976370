import React from "react";

const MailtoLink = ({ email, subject, body, children, className }) => {
  return (
    <a
      className={className}
      href={`mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`}
    >
      {children}
    </a>
  );
};

export default MailtoLink;
