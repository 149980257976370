import React from "react";
import classes from "./Button.module.scss";

const button = (props) => {
  return (
    <button
      onClick={props.clicked}
      disabled={props.disabled}
      className={`${classes.btn} ${props.disabled ? classes.btn_disabled : ''} ${props.className ? props.className : ''}`}
    >
      {props.children}
    </button>
  );
};

export default button;
