import React from "react";
import { useLocation } from "react-router-dom";

const VerifyEmailAddress = (props) => {
  // let location = useLocation();
  // let params = new URLSearchParams(location.search);
  // let mode = params.get("mode"); // is the string "Jonathan"
  return <div>Hi, Thanks - you're email address has been verified!!</div>;
};

export default VerifyEmailAddress;
