const searchTypes = {
  freeTextSearch: "freeTextSearch",
  specificSearch: "specificSearch",
};

const searchTypesUrl = {
  freeTextSearch: "search/listingsByFreeText",
  specificSearch: "search/listings",
};

export { searchTypes, searchTypesUrl };
