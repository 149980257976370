export const updateObject = (oldObject, newProperties) => {
  return {
    ...oldObject,
    ...newProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumericTwoDecimalPlaces) {
    const pattern = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
    isValid = pattern.test(Number(value)) && isValid;
  }

  return isValid;
};

export const isFormValid = (controls) => {
  let isValid = true;

  for (let control in controls) {
    if (!checkValidity(controls[control].value, controls[control].validation)) {
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const toSentaceCase = (condition) => {
  return condition.split(/(?=[A-Z])/).reduce((sentance, word, index) => {
    if (index === 0) {
      return toTitleCase(word.toLowerCase());
    }
    return sentance + " " + word.toLowerCase();
  }, "");
};

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
