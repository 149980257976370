import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  vehicles: [],
  error: null,
  loading: false,
};


const loadGarageStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadGarageSuccess = (state, action) => {
  return updateObject(state, {
    vehicles: action.vehicles,
    error: null,
    loading: false,
  });
};

const loadGarageFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GARAGE_START:
      return loadGarageStart(state);
    case actionTypes.LOAD_GARAGE_SUCCESS:
      return loadGarageSuccess(state, action);
    case actionTypes.LOAD_GARAGE_FAIL:
      return loadGarageFail(state, action);
    default:
      return state;
  }
};

export default reducer;