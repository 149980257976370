import * as actionTypes from "./actionsTypes";
import axios from "axios";
import { searchTypesUrl } from "../../containers/Search/searchConfig";

export const searchListingsStart = (searchType) => {
  return {
    type: actionTypes.SEARCH_LISTINGS_START,
    searchType,
  };
};

export const searchListingsSuccess = (searchResults) => {
  return {
    type: actionTypes.SEARCH_LISTINGS_SUCCESS,
    searchResults,
  };
};

export const searchListingsFail = (error) => {
  return {
    type: actionTypes.SEARCH_LISTINGS_FAIL,
    error: error,
  };
};

export const changeSearchType = (searchType) => {
  return {
    type: actionTypes.CHANGE_SEARCH_TYPE,
    searchType,
  };
};

export const searchListings = (searchCriteria, searchType) => {
  // const token = localStorage.getItem("token");
  // axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    dispatch(searchListingsStart(searchType));

    return axios
      .post(`/api/${searchTypesUrl[searchType]}/`, {
        ...searchCriteria,
        selling: "yes",
      })
      .then((response) => {
        return dispatch(searchListingsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(searchListingsFail(err.message));
      });
  };
};
