import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  listingsSearchResults: [],
  error: null,
  searchType: "freeTextSearch",
  loading: false,
};

const searchListingsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    searchType: action.searchType,
  });
};

const searchListingSuccess = (state, action) => {
  return updateObject(state, {
    listingsSearchResults: action.searchResults,
    error: null,
    loading: false,
  });
};

const searchListingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_LISTINGS_START:
      return searchListingsStart(state, action);
    case actionTypes.SEARCH_LISTINGS_SUCCESS:
      return searchListingSuccess(state, action);
    case actionTypes.SEARCH_LISTINGS_FAIL:
      return searchListingsFail(state, action);
    case actionTypes.CHANGE_SEARCH_TYPE:
      return updateObject(state, { searchType: action.searchType });
    default:
      return state;
  }
};

export default reducer;
